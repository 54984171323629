import * as actionTypes from '../actions/actionTypes';

export default (
    state = null, 
    action) => {
        switch(action.type) {
            case actionTypes.SAVE_ANSWER:
                return action.payload
            case actionTypes.CLEAR_ANSWER:
                return null
            default: 
                return state;
        }
    };
