import * as actionTypes from './actionTypes';

export const generateQuestion = () => {
    return ((dispatch, getState) => {
        let factor = getState().results.correctCounter+5
        let a = Math.ceil(Math.random()*factor)
        let b = Math.ceil(Math.random()*factor)
        let result = a + b
        let question = {
            a,
            b,
            result
        }
            dispatch(saveQuestion(question))
            dispatch({type: actionTypes.CLEAR_ANSWER})
        })
}
const saveQuestion = (question) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SAVE_QUESTION, 
            payload: {
                a: question.a,
                b: question.b,
                result: question.result
            }
        })
    }
}