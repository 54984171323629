import * as actionTypes from './actionTypes';

export const saveAnswer = (answerNumber) => {
    return (dispatch, getState) => {
        let result = getState().question.result
        answerNumber = parseInt(answerNumber, 10)
        let correct = result === answerNumber ? true : false
        dispatch({
            type: actionTypes.SAVE_ANSWER, 
            payload: {answerNumber, correct
            }
        })
        correct ? 
        dispatch({
            type: actionTypes.CORRECT_RESULT
            }
        )
        :
        dispatch({
            type: actionTypes.WRONG_RESULT
            }
        )
    }
}
export const clearAnswer = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_ANSWER
        })
    }
}