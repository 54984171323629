import * as actionTypes from '../actions/actionTypes';

export default (
    state = null, 
    action) => {
        switch(action.type) {
            case actionTypes.SAVE_QUESTION:
                return action.payload
            default: 
                return state;
        }
    };
