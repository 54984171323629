import * as actionTypes from '../actions/actionTypes';

export default (
    state = {correctCounter:0, wrongCounter:0}, 
    action) => {
        switch(action.type) {
            case actionTypes.CORRECT_RESULT:
                return {
                    ...state,
                    correctCounter: state.correctCounter+1
                }
            case actionTypes.WRONG_RESULT:
                return {
                    ...state,
                    wrongCounter: state.wrongCounter+1
                }
            default: 
                return state;
        }
    };
