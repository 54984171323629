import { combineReducers } from 'redux';
import question from './question';
import answer from './answer';
import results from './results';

/* STATE STRUCTURE
user:{uid, userName, userPic}
questions:{qid, title, content, answerOptions{aOId, content}}
answers:[{qId,aOId}]
results:{dim1, dim2, dim3, dim4, dim5}
*/
export default combineReducers({
    question,
    answer,
    results
    }
)