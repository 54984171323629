import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions/index';
import '../Layout/Layout.css';

class ShowQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {

        } 
      }
    render() {
        return (
            <span>
                {this.props.question !== null ?
                <span>
                    {this.props.question.a} + {this.props.question.b} = 
                </span>
                :
                null}
            </span>
        )
    }
}
const mapStateToProps = state => {
    return {
        question: state.question
    }
}

const mapDispatchToProps = dispatch => {
    dispatch(actionCreators.generateQuestion())
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (ShowQuestion)