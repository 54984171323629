import React, {Component} from 'react';
import {connect} from 'react-redux';
import ShowQuestion from '../Components/ShowQuestion';
import AddAnswer from '../Components/AddAnswer';
import * as actionCreators from '../store/actions/';
import ShowResult from '../Components/ShowResult';
import './Layout.css';

class Layout extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
      }
    render(){
        return (
            <div className='Layout'>
                <div className='LayoutTitle'>
                    NUMBERS
                </div>
                <div className='ShowQuestion'>
                <ShowResult/>
                <ShowQuestion/>
                <AddAnswer/></div>
            </div>
        );
    } 
}
const mapStateToProps = state => {
    return {
        question: state.question,
        answer: state.answer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        generateQuestion : () => dispatch(actionCreators.generateQuestion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (Layout)