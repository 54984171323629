import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions/index';
import '../Layout/Layout.css';

class AddAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answerNumber: '', 
            focus:true} 
        this.inputFocus = React.createRef()
    }
    componentDidMount = ()=>{
        if (this.props.answer === null) {
            this.inputFocus.current.focus()
        }
    }
    componentDidUpdate = ()=>{
        if (this.props.answer === null) {
            this.inputFocus.current.focus()
        }
    }
    updateAnswerNumber = (event) =>{
        this.setState({
            answerNumber : event.target.value
        })
    }
    submitAnswer = (answerNumber, event) =>{
        event.preventDefault()
        this.props.submitAnswer(this.state.answerNumber)
        this.setState({answerNumber:''})
    }
    focusInput = ()=> {
        this.inputFocus.current.focus();
      }
    render() {
        return (
            <span>
                {this.props.answer === null ?
                <span className='AddAnswer'>
                   <div> <form>
                        <input 
                            type='number' 
                            onChange={(event)=>this.updateAnswerNumber(event)} 
                            value={this.state.answerNumber}
                            ref= {this.inputFocus}
                        >
                        </input>
                        <button 
                            onClick={(event)=>this.submitAnswer(this.state.answerNumber, event)}>
                            Senden
                        </button>
                    </form>
                    </div>  
                </span>
                :
                <span> {this.props.answer.answerNumber}</span>
                }
                
            </span>
        )
    }
}
const mapStateToProps = state => {
    return {
        question: state.question,
        answer: state.answer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        submitAnswer : (answerNumber)=> dispatch(actionCreators.saveAnswer(answerNumber)) 
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (AddAnswer)