import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions/index';
import '../Layout/Layout.css';

class ShowResult extends Component {
    constructor(props) {
        super(props);
        this.state = {} 
        this.inputFocus = React.createRef()
    }
    componentDidMount = ()=>{
        if (this.props.answer !== null) {
            this.inputFocus.current.focus()
        }
    }
    componentDidUpdate = ()=>{
        if (this.props.answer !== null) {
            this.inputFocus.current.focus()
        }
    }
    
    render() {
        return (
            <div className='ShowResult'>
                {this.props.answer !== null ? 
                !this.props.answer.correct  ?
                <div>
                    <div>Das ist leider nicht richtig - die richtige Antwort ist {this.props.question.result}</div>
                    <br/>
                    <form>
                    <button 
                        onClick={this.props.generateQuestion}
                        ref={this.inputFocus}>
                    Nächste Aufgabe
                    </button>
                    </form>
                </div>
                :
                <div>
                    <div>Super, das ist richtig!</div>
                    <br/>
                    <button 
                        onClick={this.props.generateQuestion}
                        ref={this.inputFocus}>
                    Nächste Aufgabe</button>
                </div>
            :
            <div>
                Aktuelle Aufgabe: Was ist das richtige Ergebnis?
            </div>
            }
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        question: state.question,
        answer: state.answer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        generateQuestion : ()=> dispatch(actionCreators.generateQuestion()) 
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (ShowResult)